import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const sijetais = () => (
  <Layout>
    <SEO title="Bananes Citronnées - Si j'étais" />
    <h3 className='underline-title'>Si j'étais...</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Si j'étais Le-Bon-Dieu<br />
        Je réediterais le déluge biblique<br/>
        J'engloutirais toute la terre<br/>
        Afin qu'aucun homme n'y vive<br/>
      </p>
      <p>
        Si j'étais Jésus-Christ<br />
        Je me saisirais de mon fouet<br/>
        Je fustigerais ces mercantis cabochards<br/>
        Qui profanent la maison de mon Père<br/>
      </p>
      <p>
        Si j'étais Souverain Pontife<br />
        Je frapperais d'anathème total<br/>
        Ces cafards de chrétiens allogènes<br/>
        Qui recrucifient la Galiléen<br/>
      </p>
      <p>
        Si j'étais Roi-Imberbe<br />
        Je bannirais ces chercheurs d'or sans foi<br/>
        Qui font la honte de la race d'Outre-Mer<br/>
        Au pays du Corbeau<br/>
      </p>
      <p>
        Si j'étais Cicéron-l'éloquent<br />
        Je défendrais les Siciliens<br/>
        Je prononcerais les Verrines<br/>
        Contre le propréteur concussionnaire<br/>
      </p>
      <p>
        Si j'étais un Prince d'Afrique<br />
        J'expulserais ces cycliques allochtones<br/>
        Qui récoltent sans avoir investi<br/>
      </p>
      <p>
        Si j'étais quelqu'un du patrice<br />
        Je refoulerais rudement encore<br/>
        Ces spéculateurs métèques<br/>
        Qui fricotent à notre barbe<br/>
      </p>
      <p>
        Si j'étais juge autochtone<br />
        Je ne relâcherais aucunement<br/>
        Ces tartufes zaïrois de la clique<br/>
        Traîtant avec ces véreux du gang<br/>
      </p>
      <p>
        Si j'étais surtout Ngongo Lutété<br />
        Je trancherais le bras droit<br/>
        A toute la pègre du chinchona<br/>
        Qui crotte sur les trottoirs de Bukavu<br/>
        Joyau régional du Zaïre authentique<br/>
      </p>
      <p>
        Point à vous inquiéter citoyens ANGWALIMA<br />
        Rassurez-vous messieurs AL CAPONE<br/>
        Je ne suis pas ce que je voudrais être<br/>
        Je ne suis pas ce que vous savez que je suis<br/>
        Opérez donc en paix!<br/>
      </p>
    </div>
    <hr/>
    <p className="date-published">Bukavu, le 30 octobre 1975</p>
    <br />
    <br />
    <Link to="/bananes-citronnees-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default sijetais
